<template>
  <div>
    <p class="text-lg font-semibold" :class="classes" v-if="show">
      {{ $t("objectivesList." + objective + ".fullText") }}
    </p>
  </div>
</template>

<style scoped>
/* .ruled-out {
  text-decoration: line-through;
  text-decoration-thickness: 2px;
  text-decoration-color: #ef4444b0;
} */

.ruled-out {
  position: relative;
  display: inline-block;
}
.ruled-out::before {
  content: "";
  border-bottom: 4px solid #ef444499;
  width: 100%;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: skewY(-0.5deg);
  transform: skewY(-0.5deg);
}
</style>

<script>
export default {
  props: {
    state: {
      type: String,
      default: "no",
    },
    objective: {
      type: String,
      required: true,
    },
  },
  computed: {
    show() {
      return this.state != "no";
    },
    classes() {
      return this.state == "yes" ? "text-white" : "ruled-out text-gray-400";
    },
  },
};
</script>
