// import firebase from "firebase/app";
// import "firebase/database";
// import "firebase/analytics";
// import "firebase/firestore";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyAlsWQ7QIccwXPEXT-ho30HDii3BtkbQRM",
  authDomain: "phasmojournal.firebaseapp.com",
  databaseURL: "https://phasmojournal-default-rtdb.firebaseio.com",
  projectId: "phasmojournal",
  storageBucket: "phasmojournal.appspot.com",
  messagingSenderId: "695907430683",
  appId: "1:695907430683:web:b8df56cb851f868d62a3be",
  measurementId: "G-P0032CKB1M",
};

initializeApp(firebaseConfig);

const db = getDatabase();
// const analytics = firebase.analytics();
const fdb = getFirestore();

export { db, fdb };
