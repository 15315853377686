<template>
  <div
    class="
      bg-gray-800 bg-opacity-90
      blur-md
      rounded-3xl
      py-4
      px-6
      bottom-0
      fixed
      text-left
    "
  >
    <div class="flex flex-row justify-between content-center text-left mb-2">
      <h1 class="font-bold text-3xl text-white">
        {{ ghostName || "Ghost Name" }}
      </h1>
      <h1 class="font-bold text-3xl text-white bg-red-500 rounded-md px-2 ml-4">
        {{ ghostType }}
      </h1>
    </div>
    <div class="flex mt-4 mb-2">
      <evidence
        v-for="(state, evidence) in evidence"
        :key="evidence"
        :evidence="evidence"
        :state="state"
      />
    </div>
    <div v-if="matchingGhosts.length > 1 && matchingGhosts.length < 6">
      <p class="italic text-lg font-medium text-white max-w-xl">
        {{ possibleGhostsText }}
      </p>
    </div>
    <h2 class="font-extrabold text-xl text-red-500">Objectives</h2>
    <objective
      v-for="(state, objective) in objectives"
      :key="objective"
      :state="state"
      :objective="objective"
    />
    <p
      class="
        text-lg
        mt-2
        text-white
        font-semibold
        text-center
        bg-red-500
        rounded-lg
      "
    >
      phasmo.lotai.xyz
    </p>
  </div>
</template>

<script>
import { doc, onSnapshot } from "firebase/firestore";
import { onValue, ref } from "firebase/database";
import { fdb, db } from "../firebase.js";
import Objective from "../components/Objective.vue";
import Evidence from "../components/Evidence.vue";
import ghostsDataJson from "../assets/ghost_data.json";

var dbRef;
var roomUpdateListner;

export default {
  data() {
    return {
      ghostName: "",
      evidence: {},
      objectives: {},
      answersEveryone: true,
      roomId: "",
      ghostsData: ghostsDataJson,
    };
  },
  props: {
    overlayId: {
      type: String,
      required: true,
    },
  },
  computed: {
    matchingGhosts() {
      var res = [];
      this.ghostsData.forEach((ghost) => {
        for (var evidence in this.evidence) {
          const state = this.evidence[evidence];
          if (state == "yes") {
            if (!ghost.evidence.includes(evidence)) {
              return;
            }
          } else if (state == "ruledOut") {
            if (ghost.evidence.includes(evidence)) {
              return;
            }
          }
        }
        res.push(ghost);
      });
      return res;
    },
    ghostType() {
      return this.matchingGhosts.length == 1
        ? this.$t("ghosts." + this.matchingGhosts[0].name + ".name")
        : "Unknown";
    },
    possibleGhostsText() {
      let ghostNames = [];
      this.matchingGhosts.forEach((ghost) => {
        ghostNames.push(this.$t("ghosts." + ghost.name + ".name"));
      });
      return ghostNames.join(", ") + "...?";
    },
  },
  components: {
    Objective,
    Evidence,
  },
  mounted() {
    onSnapshot(doc(fdb, "overlays", this.overlayId), (document) => {
      if (roomUpdateListner) {
        roomUpdateListner();
      }
      this.roomId = document.data().roomId;
      dbRef = ref(db, "rooms/" + this.roomId);
      roomUpdateListner = onValue(dbRef, (snapshot) => {
        var value = snapshot.val();
        console.log(value);
        if ("ghostName" in value) {
          this.ghostName = value.ghostName;
        }
        if ("answersEveryone" in value) {
          this.answersEveryone = value.answersEveryone;
        }
        if ("evidence" in value) {
          this.evidence = value.evidence;
        }
        if ("objectives" in value) {
          this.objectives = value.objectives;
        }
      });
    });
  },
};
</script>
