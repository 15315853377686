<template>
  <div :class="classes" class="px-2 py-2 rounded-md mx-2">
    <img :src="'/' + evidence + '.svg'" class="w-12 h-12" draggable="false" />
  </div>
</template>

<script>
export default {
  props: {
    evidence: {
      type: String,
    },
    state: {
      type: String,
    },
  },
  computed: {
    classes() {
      if (this.state == "yes") {
        return "bg-green-500";
      } else if (this.state == "no") {
        return "bg-red-500";
      } else if (this.state == "ruledOut") {
        return "bg-red-500 bg-opacity-50";
      }
      return "";
    },
  },
};
</script>
