export default {
  "phasmojournal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phasmojournal"])},
  "game": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game"])},
  "questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questions"])},
  "maps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maps"])},
  "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
  "answersEveryone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answers Everyone"])},
  "answersAlone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answers Alone"])},
  "ghostName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ghost name"])},
  "evidence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evidence"])},
  "emf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EMF"])},
  "fingerprints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fingerprints"])},
  "writing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Writing"])},
  "ghostOrb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ghost orb"])},
  "spiritBox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spirit Box"])},
  "temperature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temperature"])},
  "dots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DOTS"])},
  "objectives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objectives"])},
  "createRoom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create room"])},
  "copyLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy link"])},
  "wiki": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])},
  "strength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strength"])},
  "weakness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weakness"])},
  "ghosts": {
    "banshee": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banshee"])},
      "strength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only targets one player at a time if they are in the building."])},
      "weakness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Increased Crucifix radius."])},
      "wikiLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://phasmophobia.fandom.com/wiki/Banshee"])}
    },
    "demon": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demon"])},
      "strength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extremely agressive.  Will hunt earlier and more often."])},
      "weakness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Using correct Ouija board questions does not drain sanity."])},
      "wikiLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://phasmophobia.fandom.com/wiki/Demon"])}
    },
    "jinn": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jinn"])},
      "strength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moves faster the further away you are from it."])},
      "weakness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cutting off the building’s power supply will limit the Jinn’s speed."])},
      "wikiLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://phasmophobia.fandom.com/wiki/Jinn"])}
    },
    "mare": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mare"])},
      "strength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More aggressive when the lights in the room are off.  Likes to switch off lights."])},
      "weakness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More passive when the lights are on."])},
      "wikiLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://phasmophobia.fandom.com/wiki/Mare"])}
    },
    "oni": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oni"])},
      "strength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More activity with more than 1 person in the room.  Can throw objects quickly."])},
      "weakness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Being more active makes it easier to find."])},
      "wikiLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://phasmophobia.fandom.com/wiki/Oni"])}
    },
    "phantom": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phantom"])},
      "strength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Looking at the Phantom will reduce your sanity 2x more."])},
      "weakness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The phantom will be invisible in the photo and dissapear when you take a photo, except during a hunt."])},
      "wikiLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://phasmophobia.fandom.com/wiki/Phantom"])}
    },
    "poltergeist": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poltergeist"])},
      "strength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can throw multiple objects at once."])},
      "weakness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ineffective in empty rooms with no throwables."])},
      "wikiLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://phasmophobia.fandom.com/wiki/Poltergeist"])}
    },
    "revenant": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revenant"])},
      "strength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2x speed when chasing someone, can freely switch target during chase."])},
      "weakness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0.5x speed when it's not chasing anyone."])},
      "wikiLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://phasmophobia.fandom.com/wiki/Revenant"])}
    },
    "shade": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shade"])},
      "strength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heavilty reduced activity and hunts with more than 1 person in the room."])},
      "weakness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Less likely to hunt if players are grouped together."])},
      "wikiLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://phasmophobia.fandom.com/wiki/Shade"])}
    },
    "spirit": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spirit"])},
      "strength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["None."])},
      "weakness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smudge sticks will stop the ghost from hunting for 2x the amount."])},
      "wikiLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://phasmophobia.fandom.com/wiki/Spirit"])}
    },
    "wraith": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wraith"])},
      "strength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Won't leave UV footprints when walking in salt, can teleport to a random player's location."])},
      "weakness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stepping in salt will increase the ghost's activity."])},
      "wikiLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://phasmophobia.fandom.com/wiki/Wraith"])}
    },
    "yurei": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yurei"])},
      "strength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stronger sanity drain during manifestation."])},
      "weakness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Using smudge sticks on the ghost will cause it to not wander."])},
      "wikiLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://phasmophobia.fandom.com/wiki/Yurei"])}
    },
    "yokai": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yokai"])},
      "strength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talking near a Yokai will anger it and increase it's chance of attacking."])},
      "weakness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When hunting, a Yokai can only hear voices close to it."])},
      "wikiLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://phasmophobia.fandom.com/wiki/Yokai"])}
    },
    "hantu": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hantu"])},
      "strength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moves faster in cooler areas."])},
      "weakness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moves slower in warmer areas."])},
      "wikiLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://phasmophobia.fandom.com/wiki/Hantu"])}
    },
    "goryo": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goryo"])},
      "strength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can only be seen with the D.O.T.S Projecter, or with the camera if nobody is nearby."])},
      "weakness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doesn't roam from the ghost room much."])},
      "wikiLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://phasmophobia.fandom.com/wiki/Goryo"])}
    },
    "myling": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Myling"])},
      "strength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is much quiter when hunting."])},
      "weakness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Makes more paranormal sounds."])},
      "wikiLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://phasmophobia.fandom.com/wiki/Myling"])}
    },
    "obake": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obake"])},
      "strength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When interacting with the environment, an Obake will rarely leave a trace."])},
      "weakness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sometimes this ghost will shapeshift, leaving behind unique evidence."])},
      "wikiLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://phasmophobia.fandom.com/wiki/Obake"])}
    },
    "onryo": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onryo"])},
      "strength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extinguishing a flame can cause an Onryo to attack."])},
      "weakness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When threatened, this ghost will be less likely to attack."])},
      "wikiLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://phasmophobia.fandom.com/wiki/Onryo"])}
    },
    "raiju": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raiju"])},
      "strength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Raiju can siphon power from nearby electrical devices, making it move faster."])},
      "weakness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raiju are constantly disrupting electronic equipment, making it easier to track when attacking."])},
      "wikiLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://phasmophobia.fandom.com/wiki/Raiju"])}
    },
    "theTwins": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Twins"])},
      "strength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Either Twin can be angered and initiate an attack on their prey."])},
      "weakness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Twins will often interact with the environment at the same time."])},
      "wikiLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://phasmophobia.fandom.com/wiki/The_Twins"])}
    },
    "theMimic": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Mimic"])},
      "strength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Mimic is able to mirror the traits of other ghosts. The Mimic may change the ghost type that it imitates."])},
      "weakness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Mimic also provides evidence of Ghost Orbs in addition to its main evidence types."])},
      "wikiLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://phasmophobia.fandom.com/wiki/The_Mimic"])}
    },
    "deogen": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deogen"])},
      "strength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiding is useless, the Deogen will move to the location of a player and is faster the further away you are."])},
      "weakness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It slows down the closer you are to it.  The Deogen is the only ghost that breathes heavily through the spirit box."])},
      "wikiLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://phasmophobia.fandom.com/wiki/Deogen"])}
    },
    "moroi": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moroi"])},
      "strength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A player cursed by the Moroi will have greater sanity reduction during especially during hunts and events, lights/candles have no effect."])},
      "weakness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smug sticks stun the ghost for 12 seconds instead of 6 during a hunt."])},
      "wikiLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://phasmophobia.fandom.com/wiki/Moroi"])}
    },
    "thaye": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thaye"])},
      "strength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is more active/aggressive at the start/with a higher sanity."])},
      "weakness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is less active/aggressive over time or as sanity drains."])},
      "wikiLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://phasmophobia.fandom.com/wiki/Thaye"])}
    }
  },
  "objectivesList": {
    "crucifix": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crucifix"])},
      "fullText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prevent the Ghost from hunting with a Crucifix"])}
    },
    "event": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event"])},
      "fullText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Have a member of your team witness a Ghost Event"])}
    },
    "motion": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motion"])},
      "fullText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detect a Ghosts presence with a Motion Sensor"])}
    },
    "photo": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photo"])},
      "fullText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capture a photo of the Ghost"])}
    },
    "emf": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EMF"])},
      "fullText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find evidence of the paranormal with an EMF Reader"])}
    },
    "smudge": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smudge"])},
      "fullText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cleanse the area near the Ghost using Smudge Sticks"])}
    },
    "candle": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Candle"])},
      "fullText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detect a Ghost presence with a Candle"])}
    },
    "salt": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salt"])},
      "fullText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get a Ghost to walk through Salt"])}
    },
    "sanity": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sanity"])},
      "fullText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get an average Sanity below 25%"])}
    },
    "hunt": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hunt"])},
      "fullText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escape the Ghost during a Hunt with no deaths"])}
    },
    "repel": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repel"])},
      "fullText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use Smudge Sticks while the Ghost is hunting a player"])}
    },
    "parabolic": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parabolic"])},
      "fullText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detect a paranormal sound with a Parabolic Microphone"])}
    }
  }
}